import {
    cssVal,
    getDataByCodePostal,
    newInput, texteForCivilite,
    tryToDate, visible, hidden, getDataBySiren,
} from "@/data/helpers";
import {def} from "@/data/dictionnaire";

export const finLocationGerances = {
    title: "Fin de Location Gérance",
    texte: "Le contrat de location gérance consenti par {{bailleurGroup}}, à {{locataireGerantGroup}}, concernant un fonds de commerce de {{fondsGroup.activiteStr}} sis et exploité au {{fondsGroup.adresse}}[[, {{fondsGroup.adresseSuite}}]], {{fondsGroup.codePostal}} {{fondsGroup.ville}}, a pris fin le {{fondsGroup.date}}",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "bailleurGroup":
                if(varText['bailleurGroup.typePersonne'] === 'morale') {
                    tmpText = `la société <strong>${cssVal(varText['bailleurGroup.societeDenomination'])}</strong>, ${cssVal(varText['bailleurGroup.societeFormeJuridique'])} au capital de ${cssVal(varText['bailleurGroup.societeCapital'])} €, siège au ${cssVal(varText['bailleurGroup.societeAdresse'])}, ${cssVal(varText['bailleurGroup.societeAdresse2'])} ${cssVal(varText['bailleurGroup.societeCodePostal'])} ${cssVal(varText['bailleurGroup.societeVille'])}, Rcs ${cssVal(varText['bailleurGroup.societeRcsVille'])} ${cssVal(varText['bailleurGroup.societeRcs'])}`;
                } else {
                    tmpText = `<strong>${texteForCivilite(varText['bailleurGroup.civilite'])} ${cssVal(varText['bailleurGroup.prenom'])} ${cssVal(varText['bailleurGroup.nom'])}</strong> ${cssVal(varText['bailleurGroup.societeAdresse'])}, ${cssVal(varText['bailleurGroup.societeAdresse2'])} ${cssVal(varText['bailleurGroup.societeCodePostal'])} ${cssVal(varText['bailleurGroup.societeVille'])}`;
                }
                break;
            case "locataireGerantGroup":
                if(varText['locataireGerantGroup.typePersonne'] === 'morale') {
                    tmpText = `la société <strong>${cssVal(varText['locataireGerantGroup.denomination'])}</strong>, ${cssVal(varText['locataireGerantGroup.formesSocietes'])} au capital de ${cssVal(varText['locataireGerantGroup.montantCapital'])} €, siège au ${cssVal(varText['locataireGerantGroup.adresse'])}, ${cssVal(varText['locataireGerantGroup.adresseSuite'])} ${cssVal(varText['locataireGerantGroup.codePostal'])} ${cssVal(varText['locataireGerantGroup.ville'])}, Rcs ${cssVal(varText['locataireGerantGroup.lieuImmatriculation'])} ${cssVal(varText['locataireGerantGroup.rcs'])}`;
                } else {
                    tmpText = `<strong>${texteForCivilite(varText['locataireGerantGroup.civilite'])} ${cssVal(varText['locataireGerantGroup.prenom'])} ${cssVal(varText['locataireGerantGroup.nom'])}</strong> ${cssVal(varText['locataireGerantGroup.adresse'])}, ${cssVal(varText['locataireGerantGroup.adresseSuite'])} ${cssVal(varText['locataireGerantGroup.codePostal'])} ${cssVal(varText['locataireGerantGroup.ville'])}`;
                }
                break;
            case "fondsGroup.date":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        bailleurGroup: [{}],
        locataireGerantGroup: [{}],
        fondsGroup: [{}],
    },
    schema: [
        {
            //0
            name: "bailleurGroup",
            label: "Bailleur",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            finLocationGerances.schema[0].children[1] = val === "physique" ? visible(finLocationGerances.schema[0].children[1]) : hidden(finLocationGerances.schema[0].children[1]);
                            finLocationGerances.schema[0].children[2] = val === "physique" ? visible(finLocationGerances.schema[0].children[2]) : hidden(finLocationGerances.schema[0].children[2]);
                            finLocationGerances.schema[0].children[3] = val === "physique" ? visible(finLocationGerances.schema[0].children[3]) : hidden(finLocationGerances.schema[0].children[3]);

                            finLocationGerances.schema[0].children[4] = val === "morale" ? visible(finLocationGerances.schema[0].children[4]) : hidden(finLocationGerances.schema[0].children[4]);
                            finLocationGerances.schema[0].children[5] = val === "morale" ? visible(finLocationGerances.schema[0].children[5]) : hidden(finLocationGerances.schema[0].children[5]);
                            finLocationGerances.schema[0].children[6] = val === "morale" ? visible(finLocationGerances.schema[0].children[6]) : hidden(finLocationGerances.schema[0].children[6]);
                            finLocationGerances.schema[0].children[7] = val === "morale" ? visible(finLocationGerances.schema[0].children[7]) : hidden(finLocationGerances.schema[0].children[7]);
                            finLocationGerances.schema[0].children[8] = val === "morale" ? visible(finLocationGerances.schema[0].children[8]) : hidden(finLocationGerances.schema[0].children[8]);

                        }
                    }
                }),

                newInput(def.civilite, '_required'),//
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {name: 'societeRcs',
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            finLocationGerances.model.bailleurGroup[0] = Object.assign(finLocationGerances.model.bailleurGroup[0], data.values.societe, data.values.siegeSocial);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            finLocationGerances.schema[0].children[12].options = dataCP.options;
                            finLocationGerances.model.bailleurGroup[0].societeVille = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}, 'hidden'),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}, 'hidden'),
                newInput(def.formesSocietes, '_required', {name: 'societeFormeJuridique'}, 'hidden'),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}, 'hidden'),

                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            finLocationGerances.schema[0].children[12].options = data.options;
                            finLocationGerances.model.bailleurGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
            ]
        },
        {
            //1
            name: "locataireGerantGroup",
            label: "Locataire gérant",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            finLocationGerances.schema[1].children[1] = val === "physique" ? visible(finLocationGerances.schema[1].children[1]) : hidden(finLocationGerances.schema[1].children[1]);
                            finLocationGerances.schema[1].children[2] = val === "physique" ? visible(finLocationGerances.schema[1].children[2]) : hidden(finLocationGerances.schema[1].children[2]);
                            finLocationGerances.schema[1].children[3] = val === "physique" ? visible(finLocationGerances.schema[1].children[3]) : hidden(finLocationGerances.schema[1].children[3]);

                            finLocationGerances.schema[1].children[4] = val === "morale" ? visible(finLocationGerances.schema[1].children[4]) : hidden(finLocationGerances.schema[1].children[4]);
                            finLocationGerances.schema[1].children[5] = val === "morale" ? visible(finLocationGerances.schema[1].children[5]) : hidden(finLocationGerances.schema[1].children[5]);
                            finLocationGerances.schema[1].children[6] = val === "morale" ? visible(finLocationGerances.schema[1].children[6]) : hidden(finLocationGerances.schema[1].children[6]);
                            finLocationGerances.schema[1].children[7] = val === "morale" ? visible(finLocationGerances.schema[1].children[7]) : hidden(finLocationGerances.schema[1].children[7]);
                            finLocationGerances.schema[1].children[8] = val === "morale" ? visible(finLocationGerances.schema[1].children[8]) : hidden(finLocationGerances.schema[1].children[8]);

                        }
                    }
                }),
                newInput(def.civilite, '_required'),//
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            finLocationGerances.model.locataireGerantGroup[0] = Object.assign(finLocationGerances.model.locataireGerantGroup[0], data.values.all);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            finLocationGerances.schema[1].children[12].options = dataCP.options;
                            finLocationGerances.model.locataireGerantGroup[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            finLocationGerances.schema[1].children[12].options = data.options;
                            finLocationGerances.model.locataireGerantGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //2
            name: "fondsGroup",
            label: "Le fonds",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            finLocationGerances.schema[2].children[3].options = data.options;
                            finLocationGerances.model.fondsGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
                newInput(def.activiteStr, '_required'),
                newInput(def.date, '_required'),
            ]
        },
    ]
}